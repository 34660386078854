import React from 'react';
import { Button,Label } from 'semantic-ui-react'
import './index.css'


const NotFound2 = ()=>{
  return (
    <div>
      <Label>這是空頁面</Label>
    </div>
  )
}



export default NotFound2;