import React, { Component } from 'react';
import { Responsive } from 'semantic-ui-react'
import './index.scss'
export default class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <p className='label-name'>CodeMe5@2019
            <img
              src={require('./images/Icon_triangle_right.png')}
              alt="Icon_triangle_right"
              className='icon-triangle--right'
            />
            codeme5school@gmail.com</p>
          <img
            src={require('./images/Icon_Facebook.png')}
            alt="Icon_Facebook"
            className='icon-fb'
          />
          <img
            src={require('./images/Icon_Instagram.png')}
            alt="Icon_Instagram"
            className='icon-ig'
          />
        </Responsive>

        <Responsive {...Responsive.onlyMobile}>
          <p>
            <img
              src={require('./images/Icon_triangle_right.png')}
              alt="Icon_triangle_right"
              className='icon-triangle--right-mobile'
            />
            CodeMe5@2019</p>
          <p>codeme5school@gmail.com</p>
          <img
            src={require('./images/Icon_Facebook.png')}
            alt="Icon_Facebook"
            className='icon-fb-mobile'
          />
          <img
            src={require('./images/Icon_Instagram.png')}
            alt="Icon_Instagram"
            className='icon-ig-mobile'
          />
        </Responsive>

      </div>
    )
  }
}