import React from 'react';
import { Button,Label } from 'semantic-ui-react'
import './index.css'




class ClassDetial extends React.Component {
  render() {
    console.log(this.props.match.params)
    return (
      <div>
        <Label color='blue'>這是課程詳細頁面</Label>
        <Label>{this.props.match.params.classname}</Label>
        <a href="/class">回到列表</a>
      </div>
    )
  }
}



export default ClassDetial;