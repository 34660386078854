import React from 'react';
import { Button, Label, Grid, Dropdown, Image, Header } from 'semantic-ui-react'
import SweetAlert from 'react-bootstrap-sweetalert'
import './index.scss'
import { particles_config, classList, classOptions } from '../../globalConstants'
import Particles from 'react-particles-js';
import Input from '../../components/Input/index'

const API_ENDPOINT = "https://codeme5.neversaynever.work"
class ClassList extends React.Component {

  state = {
    email: "",
    courses: [],
    others: "",
    isHideAlert: true
  }

  handleChangeUserEmail = e => this.setState({ email: e.target.value });
  handleChangeCourses = (e, courses) => {
    this.setState({ courses: courses.value });
  }
  handleChangeOthers = e => this.setState({ others: e.target.value });
  hideAlert = () => {
    window.location.reload()
  }

  handleSubmit = async () => {
    const { email, others, courses } = this.state;

    try {
      const res = await fetch(
        `${API_ENDPOINT}/interesting-list`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Content-Security-Policy": "upgrade-insecure-requests"
          },
          body: JSON.stringify({
            customer_email: email,
            classesArray: [others, ...courses]

          })
        }
      );

      if (res.status === 200 || res.status === 202) {
        console.log("api post success!!!")
        this.setState({ isHideAlert: false })
      } else {
        console.log("api post faild!!!")
      }

    } catch (e) {
      console.log(e)
    }


  };

  render() {
    return (
      <div>
        <div className='header-img-classList'>
          <Particles
            className='particles-top-classList'
            params={
              particles_config
            } />
          <div className="classList-header">
            <h1 className='title-classList'>CodeMe5 課程學習地圖</h1>
            <p className='title-desciption-classList'>
              全端開發學習地圖，最快速完整，持續動力滿滿，紮根實力！</p>
            <div className="button-center-container">
              {/* <button className='ui teal button button-top-classList' >查看近期課程</button> */}
            </div>
          </div>
        </div>
        {/* <Label>class01</Label><a href="class/01">點入課程詳細頁面</a>
      <Label>class02</Label><a href="class/02">點入課程詳細頁面</a> */}


        <section className='school-image more-bottom-space'>
          <div className="image-container-center">
            <Image src={require('../../images/class/classMap2.png')} className="image-container-center-auto" />
          </div>
          <div className="user-info-container">
            <h3>如果對我們未來要開的課程有興趣的話，歡迎留下您的Email，將通知您最新課程資訊喔！
            </h3>
            <Input
              id="username"
              value={this.state.email}
              type="text"
              label="email"
              placeholder="Email"
              handleChange={this.handleChangeUserEmail}
            />
            <h4>有興趣的課程</h4>
            <Dropdown className="drop-down" placeholder='請選擇課程' fluid multiple search selection options={classOptions}
              id="courses"
              onChange={this.handleChangeCourses}
            />
            <Input
              id="others"
              value={this.state.others}
              type="others"
              label="其他"
              placeholder="others"
              handleChange={this.handleChangeOthers}
            />
            <div className='title-introduction shorten-width'>
              <div className='button-center-container1'>
                <button className='ui teal button button-center1' onClick={this.handleSubmit}>Submit</button>
                {this.state.isHideAlert === true ? null : <SweetAlert success title="Thanks!" onConfirm={this.hideAlert}>
                  We'll be in touch.
                    </SweetAlert>}
              </div>
            </div>
          </div>
        </section>

      </div >
    )
  }
}

export default ClassList;

                    // // MARK: 暫時先不列課程lists
                    // const PERIOD_TODAY = 'Today';
// class ClassList extends React.Component {

//   clickCard = course => {
//     console.log(`${course.id} has been clicked`);
//     // this.props.push('/classroomDetail');
//     // this.props.handleGetClassDetail(this.props.token, course.id);
//     // this.props.handleGetAllSession(this.props.token, course.id);
//   };

//   render() {
//     let todayClasses = [];
//     let upcomingClasses = [];
//     classList.forEach(classList => {
//       classList.period === PERIOD_TODAY
//         ? todayClasses.push(classList)
//         : upcomingClasses.push(classList);
//     });

//     let allClasses = [
//       { period: '正在進行', classes: todayClasses },
//       { period: '即將開課', classes: upcomingClasses }
//     ];

//     return (
//       <div>
//         <div className='header-img-classList'>
//           <Particles
//             className='particles-top-classList'
//             params={
//               particles_config
//             } />
//           <div className="classList-header">
//             <h1 className='title-classList'>CodeMe5 課程學習地圖</h1>
//             <p className='title-desciption-classList'>
//               全端開發學習地圖，最快速完整，持續動力滿滿，紮根實力！</p>
//             <div className="button-center-container">
//               <button className='ui teal button button-top-classList' >查看近期課程</button>
//             </div>
//           </div>
//         </div>
//         {/* <Label>class01</Label><a href="class/01">點入課程詳細頁面</a>
//       <Label>class02</Label><a href="class/02">點入課程詳細頁面</a> */}

//         <div>
//           {allClasses.map((classList, index) => (
//             <section className="section-class" key={index}>
//               {classList.classes.length !== 0 && (
//                 <h1 className='class-period'>{classList.period}</h1>
//               )}
//               <ul>
//                 {classList.classes.map(course => (
//                   <li
//                     key={`card-${course.id}`}
//                     className='block--width-free'
//                   >
//                     <div className="card-icon" onClick={() => this.clickCard(course)}>
//                       <img
//                         className='img-top'
//                         src={
//                           course.imageUrl
//                             ? course.imageUrl
//                             : require('../../images/class/gallery-image-7.jpg')
//                         }
//                         alt={course.className}
//                       />
//                       <h3>{course.language}</h3>
//                       <p>{course.className}</p>
//                       <p className='class-comment'>{course.description}</p>
//                     </div>
//                   </li>
//                 ))}
//               </ul>
//             </section>
//           ))}
//         </div>

//       </div>
//     )
//   }
// }
