import _ from "lodash";
import React, { Component } from 'react';
// import { Button,Navbar,Nav,NavItem,NavDropdown,MenuItem } from 'react-bootstrap';
import './index.scss'

import {
  Segment,
  Icon,
  Image,
  Menu,
  Sidebar,
  Responsive,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const NavBarMobile = ({
  children,
  onPusherClick,
  onToggle,
  rightItems,
  visible,
  activeItem,
  handleItemClick,
}) => (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        direction='right'
        inverted
        vertical
        visible={visible}
        active={activeItem}
        handleItemClick={handleItemClick}
      >
        {_.map(rightItems, item => <Menu.Item {...item} className='sidebar-mobile' />)}
      </Sidebar>

      <Sidebar.Pusher
        dimmed={visible}
        onClick={onPusherClick}
        style={{ minHeight: "100vh" }}
      >
        <Menu fixed='top' inverted style={{ position: 'absolute' }}>
          <Menu.Item
            as={Link} to='/'
            name=''
            activeItem={'codeme5'}
            onClick={'codeme5'}
            className='item-mobile'
          >
            <Image size="tiny" src='./images/logo-white.png' />
          </Menu.Item>
          <Menu.Item onClick={onToggle} position='right' className='item-mobile'>
            <Icon name="sidebar" color='teal' />
          </Menu.Item>
        </Menu>
        {/* 內容頁面必須放到這裡面 */}
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );

// const NavBarDesktop = ({ leftItems, rightItems }) => (
//   <Segment inverted pointing secondary fixed='top' color='#ffff' style={{ minHeight: 60 }}>
//     <Menu inverted pointing secondary fixed='top' color='#ffff'>
//       <Menu.Item
//         as={Link} to='/'
//         name=''
//         activeItem={'codeme5'}
//         onClick={'codeme5'}
//       >
//         <Image size="tiny" src='./images/logo-white.png' />
//       </Menu.Item>
//       <Menu.Menu position="right">
//         {_.map(rightItems, item => <Menu.Item style={{ color: 'white' }} {...item} />)}
//       </Menu.Menu>
//     </Menu>
//   </Segment >

// );

const NavBarChildren = ({ children }) => (
  < div fluid className='container_main' > {children}</div>
);

const leftItemsGet = [
  { as: Link, to: '/', content: "", key: "課程", activeItem: 'codeme5', onClick: 'codeme5' },
];
const rightItemsGet = [
  { as: Link, to: '/class', content: "課程學習地圖", key: "課程", activeItem: '課程', onClick: '課程' },
  // { as: Link, to: '/activity', content: "活動", key: "活動", activeItem: '活動', onClick: '活動' },
];
export default class Navigation extends Component {

  state = {
    activeItem: 'home',
    visible: false,
    isOnTop: true
  }

  handlePusher = () => {
    const { visible } = this.state;
    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });


  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name })
  }

  componentDidMount = () => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset || document.documentElement.scrollTop != 0) {
        this.setState({ isOnTop: false })
      } else {
        this.setState({ isOnTop: true })
      }
    })
  }

  render() {
    const { children } = this.props;
    const { activeItem, visible } = this.state;

    return (
      < div >
        <Responsive {...Responsive.onlyMobile}>
          <NavBarMobile
            leftItems={leftItemsGet}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            rightItems={rightItemsGet}
            visible={visible}
            active={activeItem}
            handleItemClick={this.handleItemClick}
          >
            <NavBarChildren>{children}</NavBarChildren>
          </NavBarMobile>
        </Responsive>

        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Menu pointing secondary fixed='top' className={this.state.isOnTop === false ? 'header-dark' : null}>
            <Menu.Item
              as={Link} to='/'
              name=''
              activeItem={'codeme5'}
              onClick={'codeme5'}
            >
              <Image size="tiny" src='./images/logo-white.png' />
            </Menu.Item>

            <Menu.Menu position='right'>
              <Menu.Item
                as={Link} to='/class'
                name='課程學習地圖'
                active={activeItem === '課程'}
                onClick={this.handleItemClick}
                className={activeItem === '課程' ? 'active-tab' : 'active-tab--none-active'}
              />
              {/* <Menu.Item
                as={Link} to='/activity'
                name='活動'
                active={activeItem === '活動'}
                onClick={this.handleItemClick}
                className={activeItem === '活動' ? 'active-tab' : 'active-tab--none-active'}
              /> */}
            </Menu.Menu>

          </Menu>
          <NavBarChildren>{children}</NavBarChildren>
        </Responsive>
      </div >

      // <Segment>
      //   <Menu inverted pointing secondary fixed='top' color='#ffff'>

      //     <Menu.Item
      //       as={Link} to='/'
      //       name=''
      //       active={activeItem === 'codeme5'}
      //       onClick={this.handleItemClick}>
      //       <img src='./images/logo_white.png' />
      //     </Menu.Item>

      //     <Menu.Menu position='right'>
      //       <Menu.Item
      //         as={Link} to='/class'
      //         name='課程'
      //         active={activeItem === '課程'}
      //         onClick={this.handleItemClick}
      //       />
      //       <Menu.Item
      //         as={Link} to='/activity'
      //         name='活動'
      //         active={activeItem === '活動'}
      //         onClick={this.handleItemClick}
      //       />
      //     </Menu.Menu>

      //   </Menu>

      /* </Segment> */

    )
  }
}

// const Navigation = ()=>{
//   return (
//     <div>
//       <Navbar inverse collapseOnSelect>
//         <Navbar.Header>
//           <Navbar.Brand>
//             <a href="/">CodeMe5</a>
//           </Navbar.Brand>
//           <Navbar.Toggle />
//         </Navbar.Header>
//         <Navbar.Collapse>
//           <Nav>
//             <NavItem eventKey={2} href="/class">
//               課程報名
//             </NavItem>
//             <NavItem eventKey={2} href="/activity">
//               活動
//             </NavItem>
//             <NavDropdown eventKey={4} title="介紹" id="basic-nav-dropdown">
//               <MenuItem eventKey={4.1}>我們的優勢</MenuItem>
//               <MenuItem eventKey={4.2}>上課照片</MenuItem>
//               <MenuItem eventKey={4.3}>Q&A</MenuItem>
//               <MenuItem eventKey={4.4}>講師介紹</MenuItem>
//               <MenuItem divider />
//               <MenuItem eventKey={4.4}>課程簡介</MenuItem>

//             </NavDropdown>
//           </Nav>
//           <Nav pullRight>
//             <NavItem eventKey={1} href="#">
//               登入
//             </NavItem>
//             <NavItem eventKey={2} href="#">
//               註冊
//             </NavItem>
//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//     </div>
//   )
// }



// export default Navigation;