import React, { Component } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom' //戴入react-router需要的API
import Navigation from './components/Navigation/index.js'
import Footer from './components/Footer/index.js'
import LandingPage from './views/LandingPage'
import ClassList from './views/ClassList'
import ClassDetail from './views/ClassDetail'
import Activity from './views/Activity'
import NotFound from './views/NotFound'
// import SEO from "./components/SEO";

class App extends Component {

  // componentDidMount = () => {
  //   document.title = 'codeme5'
  // }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className='App-content'>
          {/* <SEO title='CodeMe5學程式' description='CodeMe5 學程式 | 專注於程式教學
用關卡式的成長，專案式的教學
如同 GiveMe5
希望能用一同擊掌的方式，讓你邁向成功。
歡迎報名最新課程，也歡迎任何合作與建議。
'/> */}
          <Navigation>
            <Switch>
              <Route exact path='/' component={LandingPage} />
              <Route path="/class/:classname" component={ClassDetail} />
              我們如果要取:id, 我們可用this.props.match.params
            <Route path="/class" component={ClassList} />
              {/* <Route path="/activity" component={Activity} /> */}
              <Route component={NotFound} />
            </Switch>
          </Navigation>
          <Footer />
        </div>
      </Router>

    );
  }
}

export default App;
