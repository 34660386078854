import React from 'react';
import { Image, Button, Label, Grid, Responsive } from 'semantic-ui-react'
import './index.scss'
import { schoolIntroductions, schoolQA, schoolFeedback, particles_config } from '../../globalConstants'
import Particles from 'react-particles-js';

const schoolIntroductionsImages = [
  {
    id: 1,
    title: 'javascript基礎實作課程',
    image: require('../../images/class/gallery-image-7.jpg')

  },
  {
    id: 2,
    title: 'reactNative入門實戰課程',
    image: require('../../images/class/gallery-image-8.jpg')

  }
];
const schoolIntroductionsImages2 = [
  {
    id: 1,
    title: 'react入門實作課程',
    image: require('../../images/class/gallery-image-9.jpg')

  },
  {
    id: 2,
    title: 'iOS入門實作課程',
    image: require('../../images/class/gallery-image-11.jpg')

  },
  {
    id: 3,
    title: 'web入門實作課程',
    image: require('../../images/class/gallery-image-12.jpg')

  }
];

const schoolTeacher = [
  {
    id: 1,
    title: 'Kai源',
    image: require('../../images/teachers/kaiYen.jpg'),
    description: `全端工程師，擅長React和Node.js\n
    台大畢業，曾得過兩次Hackathon冠軍，\n
    擅長快速開發，也喜歡分享程式，\n
    非資工本科系，因此\n非常能同理新手會遇到的所有問題，\n
    認為沒有教不會的學生，只有不會教的老師，\n
    希望能吸引更多人一起來玩程式，一起快樂變強！`,
    // "老師做的，就是拿人家的手錶，再告訴人家時間\n你本來就有那樣的能力，潛力\n你把時間交給我，我把能力還給你",
    experience: "臺大X國泰金控Hackathon冠軍\n女人迷Hackathon冠軍\n台科Hackathon亞軍\n\n善於用生動幽默的方式來上課，喜歡與學生互動。\n由於講師也是自學出身的，因此非常懂得學生剛學習上的困境及學習的徬徨。\n講師相信，學得快才會想學，\n希望能用最短時間幫助學員成長。"
  },
  {
    id: 2,
    title: 'Avon方方',
    image: require('../../images/teachers/avon.jpg'),
    description: `iOS/Android App工程師，\n致力於雙平台APP程式應用的開發教學與社群，\n
    也擅長react native, flutter 等跨雙平台APP框架。\n
    熱衷於社群活動，不吝於提攜新進開發者，\n
    憑藉對程式語言的學習熱情，\n攜手帶大家進入程式世界開發。\n
    完整的教學架構，讓你從0開始，\n
    教你如何跨越程式高牆，擁有編寫程式的能力，做出各式各樣的APP。`,
    // "激起你心情的漣漪，\n一起帶著熱情與勇氣，\n快樂地堅持學習，擁抱最幸福\n的藍圖天地",
    experience: "臺大X國泰金控Hackathon冠軍\n女人迷Hackathon冠軍\n台科Hackathon亞軍\n\n善於用生動幽默的方式來上課，喜歡與學生互動。\n由於講師也是自學出身的，因此非常懂得學生剛學習上的困境及學習的徬徨。\n講師相信，學得快才會想學，\n希望能用最短時間幫助學員成長。"

  },
  {
    id: 3,
    title: 'Jay',
    image: require('../../images/teachers/2.jpg'),
    description: `後端工程師，善於資料結構，大數據分析和機器學習，\n
    各種問題，立即幫你解答或教你如何找到資源，，\n
    全方位後端全攻略，教你如何學習！`,
    // "一個老師教不會學生，\n只有兩個原因\n1.老師太聰明\n2.回答太抽象",
    experience: "善於用生動幽默的方式來上課，喜歡與學生互動。\n由於講師也是自學出身的，因此非常懂得學生剛學習上的困境及學習的徬徨。\n講師相信，學得快才會想學，\n希望能用最短時間幫助學員成長。"

  }
];
class LandingPage extends React.Component {

  state = {
    introductionlist: schoolIntroductions,
    questions: schoolQA,
    introductionImages: schoolIntroductionsImages,
    introductionImages2: schoolIntroductionsImages2,
    feedbacks: schoolFeedback,
    teachers: schoolTeacher

  };

  render() {
    return (

      <div>

        <div className='header-img'>
          <Particles
            className='particles-top'
            params={
              particles_config
            } />
          <h1 className='title'>CodeMe5 學程式</h1>
          <p className='title-desciption'>
            用關卡式的成長，專案式的教學<br />
            如同GiveMe5，一同擊掌，讓你邁向成功</p>
          <a className='ui teal button button-top' href={process.env.PUBLIC_URL + "/class"} target={null}>
            查看近期課程
          </a>
        </div>


        {/* introduction */}
        <section className='title-introduction'>
          <h2>我們的優勢</h2>
          <p className='subtitle-introduction'>CodeMe5 讓非本科系的學生，也能經由努力<br />
            成為專業的軟體工程師，具有改變世界的能力。</p>

          <div className="contex-introduction">
            <ul>
              {this.state.introductionlist.map((myClass) => (
                <li
                  key={`card-${myClass.id}`}
                  className='li-intro'
                >
                  <div>
                    <Label as='a' color='teal' tag className='contex-introduction-title'>
                      {myClass.title}
                    </Label>
                    <p><br />{myClass.description}</p>
                  </div>

                </li>
              ))}
            </ul>
          </div>

          <div className="title-with-background">
            <h2>自古只有不會教的老師，沒有教不會的學生!</h2>
            <img className="triangle-double" src={require('../../images/backgroundWithShape/triangle-double-up.png')} />
          </div>
          <div className="contex-question">
            <ul>
              {this.state.questions.map((question) => (
                <li
                  key={`card-${question.id}`}
                  className='li-intro'
                >
                  <div>
                    <p>{question.title}</p>
                    <div className="ui pointing label answer-dialog">
                      <p>{question.description}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="title-with-background">
            <h2 className='title-with-background-white-title'>CodeMe5 就是你最好的學習夥伴！</h2>
            <img className="triangle-double" src={require('../../images/backgroundWithShape/triangle-double-down.png')} />
          </div>
          <h2>幫助你打好基礎，厚植實力，甚至轉職成功！</h2>
        </section>

        <section className='school-image'>
          <Grid stackable columns={2}>
            {this.state.introductionImages.map((image) => (
              <Grid.Column>
                <Image src={image.image} />
              </Grid.Column>
            ))}
          </Grid>
          <Grid stackable columns={3}>
            {this.state.introductionImages2.map((image) => (
              <Grid.Column>
                <Image src={image.image} />
              </Grid.Column>
            ))}
          </Grid>
          <h2>看看其他學員怎麼說</h2>
        </section>
        <section className='feedback-introduction'>
          <img className="triangle-double" src={require('../../images/backgroundWithShape/triangle-double-down-seperate.png')} />
          <div className='feedback-introduction-wrapper'>
            <div className='feedback-introduction-dialog-container'>
              <div className="ui right pointing label feedback-dialog feedback-dialog-first">
                <p>{this.state.feedbacks[0].description}</p>
              </div>
              <img className="feedback-person-first" src={require('../../images/icons/person.png')} />
              <div className="ui left pointing label feedback-dialog feedback-dialog-second">
                <p>{this.state.feedbacks[1].description}</p>
              </div>
              <img className="feedback-person-second" src={require('../../images/icons/person.png')} />
            </div>
          </div>
        </section>

        <section className='school-image'>
          <h2>講師群</h2>
          {/* <p className="p-comment">持續增加中，歡迎優秀講師加入</p> */}
          <Grid stackable columns={3} className="grid-teacher">
            {this.state.teachers.map((teacher) => (
              <Grid.Column>
                <Image className="teacher-image" src={teacher.image} />
                <p style={{ margin: 5, fontSize: 24 }}>{teacher.title}<br /></p>
                {
                  teacher.description.split('\n').map((item, key) => {
                    return <p key={key}>{item}</p>
                  })
                }
              </Grid.Column>
            ))}
          </Grid>
        </section>

        <section className='title-introduction'>
          <h2 className='subtitle-introduction subtitle-introduction-margin-top'>心動不如馬上行動</h2>
          <div className='button-center-container'>
            <a className='ui teal button button-center' href={process.env.PUBLIC_URL + "/class"} target={null}>查看近期課程</a>
          </div>
        </section>



      </div>
    );
  }
}

export default LandingPage;