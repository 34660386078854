import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export default class Input extends PureComponent {
    static propTypes = {
        type: PropTypes.string,
        label: PropTypes.string,
        style: PropTypes.object,
        id: PropTypes.string,
        value: PropTypes.string,
        handleChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        };
    }

    focusField = () => this.setState({ focused: !this.state.focused });

    render() {
        const { type, label, style, id, handleChange, value } = this.props;
        const { focused } = this.state;

        let inputClass = 'form-input';
        if (focused) {
            inputClass += ' form-input--focus';
        } else if (value != '') {
            inputClass += ' form-input--open';
        }

        return (
            <div className={inputClass} style={style}>
                <div className="form-input-holder">
                    <input
                        className="form-input-input"
                        type={type}
                        id={id}
                        onFocus={this.focusField}
                        onBlur={this.focusField}
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <label className="form-input-label" forhtml={id}>
                        {label}
                    </label>
                </div>
            </div>
        );
    }
}
