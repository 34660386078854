export const schoolIntroductions = [
    {
        id: 1,
        title: 'Learning Quickly',
        description: "不再是半年一年的課程，有效並快速學習！許多好的作品集，在上課一步步帶著你做，讓你帶著去求職!",
    },
    {
        id: 2,
        title: 'Class Game',
        description: "利用遊戲方式，來讓學習變快樂!能讓你在這邊認識一群能討論的夥伴！不再孤單學習！"
    },
    {
        id: 3,
        title: 'Highly Complimentary',
        description: "許多學員上過課的好評價，不怕踩雷，真正的變強！老師在業界，不怕脫離新技術！能學到業界真正需要用到的技術！"
    },
    {
        id: 4,
        title: 'Actual combat',
        description: "隨時以專案式學習，歡迎學員中途的發問，完全了解觀念！老師以幽默有趣的方式上課，讓你能不畏懼學習，累積成就感！"
    }
];

export const schoolQA = [
    {
        id: 1,
        title: '「零基礎會不會聽不懂課程？非本科系感覺好像蠻難的？」',
        description: "不會喔，只要專心上課，我們很多學員都是從零基礎開始學習的，有任何問題也可以隨時打斷老師，請老師重講，或下課後詢問老師，老師會解釋到你懂為止 :)",
    },
    {
        id: 2,
        title: '「課後如何持續讓自己成長呢？會不會上完課後就停滯了？」',
        description: "不會的，老師上課也會告訴學員關於課後的學習方法，以及如何搜尋自學，以及抱持的正確的觀念學習程式，讓你一輩子受用。"
    }
];

export const schoolFeedback = [
    {
        id: 1,
        title: 'from react class',
        description: "讓我體會到「做中學」是最有效率的學習方法，老師的引導也讓我覺得許多部分其實沒有想像中困難，學習程式的路上遇見你們真的很幸運，謝謝你們！！ :D  "
    },
    {
        id: 2,
        title: 'from react native class',
        description: "我覺得這上課方式蠻多好的! 直接實作不囉嗦，很精實～有讓第一次接觸RN的我入門上手。期待後續進階課程^^"
    }
];

// TODO: 可以再加各種不同課程（快速應用取向）
export const classOptions = [
    { key: "Ja", value: 'Ja', text: 'Javascript 入門課程' },
    { key: "前端React", value: '前端React', text: '前端React.js 入門實作課' },
    { key: "React Native", value: 'React Native', text: 'React Native 入門實作課程' },
    { key: "Flutter", value: 'Flutter', text: 'Flutter 入門實作課程' },
    { key: "Node.js 入", value: 'Node.js 入', text: 'Node.js 入門實作課程' },
    { key: "Node.js 爬", value: 'Node.js 爬', text: 'Node.js 爬蟲' },

]


// registrationStatus
// done 報名截止
// befull 即將人數額滿
// none 即將開放報名

export const classList = [
    {
        id: 1,
        language: 'Javascript',
        className: "【 Hey, Javascript - 從0開始，用五個專案帶你入手Javascript 】",
        description: "專為Javascript新手開的聚會教學活動，從0開始，一起來一場初體驗吧！",
        period: 'Upcoming',
        registrationStatus: 'befull'
    },
    {
        id: 2,
        language: 'React',
        className: "【 前端React.js入門實作課 - 用React Redux+Firebase架設web apps 】",
        description: "本課程從環境建置開始，到實作專案，手把手教學，讓你在一個假日速成網站應用程式的基礎能力。",
        period: 'Today',
        registrationStatus: 'done'
    },
    {
        id: 3,
        language: 'React Native',
        className: "【 React Native 從0到1快速上手學習攻略-入門實作課程 】",
        description: "核心技術，完整精華，必要重點，快速上手。本課程從環境建置開始，到實作專案，讓你在一個假日速成APP基礎能力。",
        period: 'Upcoming',
        registrationStatus: 'none'
    },
    {
        id: 4,
        language: 'Node.js',
        className: "【 Node.js 入門實作課程 】",
        description: "本課程從環境建置開始，到實作專案，讓你在一個假日速成基礎能力。",
        period: 'Upcoming',
        registrationStatus: 'none'
    },
];



export const particles_config = {
    "particles": {
        "number": {
            "value": 80,
            "density": {
                "enable": true,
                "value_area": 3000//800
            }
        },
        "color": {
            "value": "#ffffff"
        },
        "shape": {
            "type": "star",
            "stroke": {
                "width": 0,
                "color": "#000000"
            },
            "polygon": {
                "nb_sides": 5
            },
            "image": {
                "src": "img/github.svg",
                "width": 100,
                "height": 100
            }
        },
        "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
            }
        },
        "size": {
            "value": 5,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 80,
                "size_min": 0.1,
                "sync": false
            }
        },
        "line_linked": {
            "enable": true,
            "distance": 300,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 2
        },
        "move": {
            "enable": true,
            "speed": 3,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
            }
        }
    },
    "interactivity": {
        "detect_on": "canvas",
        "events": {
            "onhover": {
                "enable": false,
                "mode": "repulse"
            },
            "onclick": {
                "enable": true,
                "mode": "push"
            },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 800,
                "line_linked": {
                    "opacity": 1
                }
            },
            "bubble": {
                "distance": 800,
                "size": 80,
                "duration": 2,
                "opacity": 0.8,
                "speed": 3
            },
            "repulse": {
                "distance": 400,
                "duration": 0.4
            },
            "push": {
                "particles_nb": 4
            },
            "remove": {
                "particles_nb": 2
            }
        }
    },
    "retina_detect": true
}